import React from "react";
import {Link} from "react-router-dom";
import "./footer.css";
import mainlogo from "../../assests/images/logo.png";

const Footer= () => {
  return (
    <div className="footer">
      <footer className="site-footer">
        <div className="container">
          <div className="container-fluid">
            <div className="footerTop">
              <div className="row mb-0">
                <div className="col-lg-4 col-sm-12 m4 form-group35 ">
                  <p>
                    <img className="logo" src={mainlogo} alt="logo" />
                  </p>
                  <ul className="social-icons d-flex flex-wrap customecenter">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/playnootofficial/"
                      >
                        <i className="fab fa-facebook-f"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/playnootapp/"
                      >
                        <i className="fab fa-instagram"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://twitter.com/Playnoot_">
                        <i className="fab fa-twitter"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/playnoot/"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    {/* <li> <Link to={`/dashboard`} target="_blank"
                                            href="https://www.youtube.com/channel/UCxUWQAXU7D5O-EsvHTXCN3A"><i
                                                className="fab fa-youtube"></i> </Link></li> */}
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-12 m4 form-group35">
                  <h5 className="title">Quick Links</h5>
                  <div className="twoColumn">
                    <ul className="footerLinks">
                      <li>
                        <Link to={`/`}>Home </Link>
                      </li>
                      <li>
                        <Link to={`/404error`}>About Us </Link>
                      </li>
                      <li>
                        <Link to={`/404error`} href="/pricing">
                          Features
                        </Link>
                      </li>
                      <li>
                        <Link to={`/404error`} href="/features">
                          FAQs
                        </Link>
                      </li>
                      <li>
                        <Link to={`/404error`} href="javascript:void()">
                          Blog
                        </Link>
                      </li>
                    </ul>
                    <ul className="footerLinks">
                      <li>
                        <Link to={`/404error`} href="/contact-us">
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/privacypolicy`}
                          href="/policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/termsandconditions`}
                          href="/terms"
                          target="_blank"
                        >
                          Term of Use
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/cancellation-policy`}
                          href="/terms"
                          target="_blank"
                        >
                          Cancellation Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/refund-policy`}
                          href="/terms"
                          target="_blank"
                        >
                          Refund Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/delete-account`}
                          href="/terms"
                          target="_blank"
                        >
                          Delete account
                        </Link>
                      </li>
                      <li>
                        <Link to={`/cookies`} href="/terms" target="_blank">
                          Cookies
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 m4 form-group35">
                  <h5 className="title">Contacts</h5>
                  <ul className="addressSection">
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      <span>Kot Fatuhi, Hoshiarpur, Punjab India</span>
                    </li>
                    <li>
                      <i className="fas fa-phone-volume"></i>
                      <span>+91 9877011041 </span>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>{" "}
                      <Link
                        to={`/dashboard`}
                        className="contactLink"
                        href="mailto:creo@admin.com.au"
                      >
                        contact@playnoot.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright center-align">
              @All Rights Reserved by Playnoot
            </div>
          </div>
        </div>
      </footer>
    </div>
  );


}
  export default Footer;