import React,{useEffect}  from "react";
import "./home.css";
import apple from "../../assests/images/apple.png";
import playstore from "../../assests/images/play.png";
import advertisementlog from "../../assests/images/advertisementlog.png";
import mobileimg from "../../assests/images/mobileimg.png";
import aboutsection from "../../assests/images/aboutsection.png";
import abouticon1 from "../../assests/images/abouticon1.svg";
import abouticon2 from "../../assests/images/abouticon2.svg";
import abouticon3 from "../../assests/images/abouticon3.svg";
import one from "../../assests/images/1.png";
import two from "../../assests/images/2.png";
import three from "../../assests/images/3.png";
import AOS from 'aos';
import playnoot_logo_backgroupd from "../../assests/images/playnoot_logo_background.png";

import 'aos/dist/aos.css';
const Home = () => {
    useEffect(() => {
    AOS.init();
  }, [])
    return (


        <div className="home">
            <div className="bannersection">
                <div className="container">

                    <div className="row bannercontent">

                        <div className="col-lg-6 col-sm-12 position-relative"  data-aos="fade-right" data-aos-duration="2000">
                            <div className="banner-text-cover">

                                <h1 className="banner-heading">Download app, <span className="d-md-block">Start playing with</span>
                                    <span className="d-md-block">local buddies </span>
                                </h1>



                                <svg className="mb-30" width="61" height="7" viewBox="0 0 61 7" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <line x1="3.57812" y1="3.5" x2="57.2055" y2="3.5" stroke="#FCC502" stroke-width="7"
                                        stroke-linecap="round" />
                                </svg>


                                <p className="banner-subheading mb-30">Download the app and find buddies in your local area
                                    and enjoy the unlimited fun of the Indoor and outdoor games along with them.</p>


                                <div className="d-flex mt-4">
                                    <a href="javascript:void(0)"><img className="app m-2 ms-0" src={apple}
                                        alt="applestore" /></a>
                                    <a href="javascript:void(0)"><img className="app m-2" src={playstore}
                                        alt="playstore" /></a>
                                </div>

                            </div>

                        </div>
                        <div className="col-lg-6 col-sm-12" >
                            <img className="sideimgs" src={mobileimg} alt="sideimg" />
                        </div>


                    </div>
                </div>
            </div>





            <div className="aboutussection" id="aboutus">
                <div className="container">
                    <div className="aboutus-text-cover text-center" data-aos="fade-down">
                        <h1 className="aboutus-heading">About Us</h1>
                        <p className="aboutus-subheading">Its an application developed for the users to have enjoyment in there leisure time with the local buddies available in their area. The application allows to book venue and play even in groups. Many more facilities.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 text-center" data-aos="fade-up-right" data-aos-duration="1000">
                            <img className="imgAbout" src={aboutsection} alt="aboutus" />
                        </div>
                        <div className="col-lg-6 col-sm-12 text-center position-relative" data-aos="fade-up-left" data-aos-duration="1000">
                            <div className="row aboutappdeatils">
                                <div className="col-lg-6 col-sm-12 text-start">
                                    <img className="aboutusimg iconimgs" src={abouticon1} alt="1" />
                                        <p className="aboutussubheadingtext">Team Making</p>
                                        <p className="aboutus-subheading">We allow to make groups and play in teams. </p>
                                </div>
                                <div className="col-lg-6 col-sm-12 text-start">
                                    <img className="aboutusimg iconimgs" src={abouticon2} alt="2" />
                                        <p className="aboutussubheadingtext">Pre booking Venue</p>
                                        <p className="aboutus-subheading">We allow our user to book venue in advance.</p>
                                </div>
                                <div className="col-lg-6 col-sm-12 text-start">
                                    <img className="aboutusimg iconimgs" src={abouticon3} alt="3" />
                                        <p className="aboutussubheadingtext">Exclusive Offers</p>
                                        <p className="aboutus-subheading">Time to time exclusive offers for our users.</p>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="aboutussection features" id="feature">
                <div className="container">
                    <div className="aboutus-text-cover text-center" data-aos="zoom-out-up">
                        <h1 className="aboutus-heading">Features </h1>
                        <p className="aboutus-subheading">Download the app and find the local buddies to play games, chat and make connections. All just in one app .</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-12 text-center" data-aos="flip-left" data-aos-duration="2000">
                            <img className="aboutusimg" src={one} alt="1" />
                                <p className="aboutustext">Allow People To Explore
                                    The Local Buddies</p>
                        </div>
                        <div className="col-lg-4 col-sm-12 text-center" data-aos="flip-left" data-aos-duration="2000">
                            <img className="aboutusimg" src={two} alt="1" />
                                <p className="aboutustext">Connect People Through Games</p>
                        </div>
                        <div className="col-lg-4 col-sm-12 text-center" data-aos="flip-left" data-aos-duration="2000">
                            <img className="aboutusimg" src={three} alt="1" />
                                <p className="aboutustext">Easy places nearby you, at spot</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="advertisesection" id="advertisement">
                <div className="container colredyellow">
                    <div className="row align-items-center w-100 position-relative">
                        <div className="col-lg-6 col-sm-12 text-center" data-aos="zoom-in">
                            <img className="advertiseimg" src={advertisementlog} alt="mobile" />
                        </div>
                        <div className="col-lg-6 col-sm-12 pl-5" data-aos="zoom-in-up">

                            <h1 className="advertisement-heading">Download App

                            </h1>
                        <p className="aboutus-subheading">Download app now and join the sports community of your city at one click.</p>
                        <div className="d-flex customecenter">
                            <a href="javascript:void(0)"><img className="app m-2 ms-0" src={apple}
                                alt="applestore" /></a>
                            <a href="javascript:void(0)"><img className="app m-2" src={playstore}
                                alt="playstore" /></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>








  </div >
  )
  }
  export default Home;