import React from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";

const CancellationPolicy = () => {
  return (
    <div>
      <Header />
      <div className="privacy">
        <div className="container">
          <h1> Cancellation Policy</h1>
          <br />
          <div>
            <h5>
              <b>I. Booking Cancellation</b>
            </h5>
            <ul>
              <li>
                Any booking made through Playnoot app, website is susceptible to
                cancellation. Cancellation can be done by the User who made the
                booking from the Playnoot App or from the Admin panel by the
                facility owner.
              </li>
              <li>
                Cancellation policy is unique for each Play center and is
                defined by the facility owner. Therefore, while booking the
                cancellation is agreement is made between The Playnoot app user
                and Facility owner. So users are adviced to read through the
                center Cancellation Policy on the center details page{" "}
              </li>
              <li>
                Every facility booking cancellation will have defined
                cancellation guidelines which consist of
                <ul>
                  <li>
                    Cancellation period : Priod time before booking till which
                    can be canceled, for exampla, a center may allow
                    cancellation before a defined perios of time
                  </li>
                  <li>
                    Refund amount : Cancellations might be refundable or not
                    depending upon policy configuered by Facility owner. So
                    Please read cancellation policy carefully on center detail
                    page on playnoot app before booking.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h5>
              <b>II. Subscription Cancellation</b>
            </h5>
            <ul>
              <li>
                Any subascription made through Playnoot app, website is
                susceptible to cancellation. Cancellation can be done by the
                User who bought the subscription plan from the Playnoot App or
                from the Admin panel by the facility owner.
              </li>
              <li>
                Cancellation policy is unique for each Play center and is
                defined by the facility owner. Therefore, while buying a
                subscription plan the cancellation is agreement is made between
                The Playnoot app user and Facility owner. So users are adviced
                to read through the center Cancellation Policy on the center
                details page
              </li>
              <li>
                Every facility subscription plan purchase will have defined
                cancellation guidelines which consist of
                <ul>
                  <li>
                    Cancellation period : Time till which cancellation can be
                    done after the plan purchase.
                  </li>
                  <li>
                    Refund amount : Cancellations might be refundable or not
                    depending upon policy configuered by Facility owner. So
                    Please read cancellation policy carefully on center detail
                    page on playnoot app before booking.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CancellationPolicy;
