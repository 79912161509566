import logo from './logo.svg';
import './App.css';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Home from './components/Home';

import '../src/assests/fonts/font.css';
function App() {
  return (
    <div className="App">
      <Header/>   
      <Home />
      <Footer/>
    </div>
  );
}

export default App;
