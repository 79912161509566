import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { TextField, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import axios from "axios";

const ForgetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSucess, setIsSucess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  var userToken = searchParams.get("user");

  const fetchUserDetails = () => {
    setIsLoading(true);
    axios
      .get(
        "https://playnoot-api-prod.azurewebsites.net/Accounts/GetUserFromResetToken?resetToken=" +
          userToken
      )
      .then((response) => {
        setUser(response.data);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setIsFailure(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveNewUserPassword = () => {
    setIsLoading(true);
    var newPasswordRestRequest = {
      resetToken: userToken,
      userId: user.userId,
      newPassword: confirmPassword,
    };
    axios
      .post(
        "https://playnoot-api-prod.azurewebsites.net/Accounts/ResetPassword",
        newPasswordRestRequest
      )
      .then((response) => {
        console.log(response);
        setIsSucess(true);
      })
      .catch((error) => {
        console.log(error);
        setIsFailure(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNewPasswordChange = (value) => {
    setNewPassword(value);
    if (value.length <= 7) {
      setNewPasswordError(true);
    } else {
      setNewPasswordError(false);
    }
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setTimeout(() => {
      if (newPassword !== value) {
        setConfirmPasswordError(true);
      } else {
        setConfirmPasswordError(false);
      }
    }, 100);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "table-cell",
          height: "calc(100vh)",
          width: "calc(100vw)",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        {!isLoading && !isSucess && !isFailure && (
          <div>
            <img
              src={
                "https://playnootstorage.blob.core.windows.net/playnootadvertisementpictures/mainlogo.png"
              }
              style={{ height: "50px" }}
            ></img>
            <Typography sx={{ padding: "10px" }}>
              Password reset request from {user.userName}
            </Typography>
            <div style={{ padding: "10px" }}>
              <TextField
                type="password"
                label="New Password"
                value={newPassword}
                onChange={(e) => handleNewPasswordChange(e.target.value)}
                error={newPasswordError}
                helperText={
                  newPasswordError && "Password must have minimum 8 characters"
                }
              ></TextField>
            </div>
            <div style={{ padding: "10px" }}>
              <TextField
                type="password"
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                error={confirmPasswordError}
                helperText={
                  confirmPasswordError &&
                  "Confirm Password should be same as new passowrd"
                }
              ></TextField>
            </div>
            <div style={{ padding: "10px" }}>
              <button
                className="btn btn-signup"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                disabled={
                  newPassword == "" ||
                  newPasswordError ||
                  confirmPassword == "" ||
                  confirmPasswordError
                }
                onClick={() => saveNewUserPassword()}
              >
                Save
              </button>
            </div>
          </div>
        )}
        {!isLoading && !isFailure && isSucess && (
          <div>
            <CheckCircleIcon
              sx={{ fontSize: 100, color: "green", margin: "auto" }}
            ></CheckCircleIcon>
            <Typography>User password changed Successfully.</Typography>
          </div>
        )}
        {!isLoading && isFailure && !isSucess && (
          <div>
            <ErrorIcon
              sx={{ fontSize: 100, color: "red", margin: "auto" }}
            ></ErrorIcon>
            <Typography>Some error occuered !!</Typography>
            <span>You might already have used this link.</span><br/>
            <span>
              please reachout at
              <i>
                <a> developer@playnoot.com</a>
              </i>
            </span>
          </div>
        )}
        {isLoading && !isFailure && !isSucess && (
          <CircularProgress></CircularProgress>
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;
