import React from "react";
import "./error.css";
import Header from "../Header";
import Footer from "../Footer";
import error from "../../assests/images/404error.gif";
const Error = () => {
    return (<>
    <Header/>
        <div className="container">
            <div className="container text-center">
            <img className="errorimg" src={error} alt="errorpage"/>
            </div>
        </div>
    <Footer/>
   
   
    </>)}


export default Error ;