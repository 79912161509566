import React from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";

const DeleteUserPolicy = () => {
  return (
    <div>
      <Header />
      <div className="privacy">
        <div className="container">
          <h1> DELETE USER GUIDELINES</h1>
          <br />
          <p>
            If you want to delete your user account from playnoot you may do so
            using Delete Option under{" "}
            <b>
              Settings {">"} Edit Profile {">"} Delete Accont
            </b>
          </p>
          <p>
            You might not see delete user option depending upon version of app
            you installed, in that case please send us email regarding user
            deletion at <b>info@playnoot.com</b> from the same email with which
            you have registered your acount with us.
          </p>
          <p>
            We hope to serve you with the best services. Please come to explore more sports on Playnoot
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeleteUserPolicy;
