import React from "react";
import "./cookies.css";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
const Cookies = () => {
    return (
        <>
            <Header />
            <div className="cookies">

                <div className="container">
                    <h1 >Cookies</h1>
                
                    <h4 >What are cookies?</h4>
                    They are files downloaded to your computer, smartphone or device when accessing a website. Cookies allow the website to store and retrieve information about the user’s browsing habits or computer and, depending on the information it contains and the way your computer is used, may also be used to recognize the user.
                    <h4 >Cookie Types According to Managing Entity</h4>
                    Proprietary: Those sent to your computer from a computer or domain managed by the owner of the website, and from which the service the user requests is provided.

                    Third Party: These are sent to your computer when you access our website, but are from a computer or domain managed by a third party. We will notify you if the third party uses the data collected for their own purposes.

                    <h4 >Cookies that Playnoot App/Websites Uses :</h4>
                    <p>Authorization cookie</p>

                    <h4 >What happens if cookies are disabled?</h4>
                    Some service features or parts of the website may not work properly. Not accepting cookies means a rejection cookie will be installed on your computer, informing us that you don’t want cookies on future visits.
                    <h4 >Do we update our cookie policy?</h4>
                    It’s possible, therefore we recommend reviewing this policy every time you visit our website to be properly informed about how and for what we use cookies.
                </div>

            </div>
            <Footer />
        </>
    )
}


export default Cookies;