import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import Header from "../../shared/Header";
import Footer from "../../shared/Footer";

import axios from "axios";

import "./payment-status.css";

const PaymentStatus = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  var paymentId = searchParams.get("paymentId");
  var payment_for = searchParams.get("payment_for");
  var token = searchParams.get("token");

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const fetchPaymentDetails = () => {
    setIsLoading(true);
    axios
      .get(
        "https://playnoot-api-prod.azurewebsites.net/MobilePayment/GetPhonepePaymentDetails?phonepePaymentId=" +
          paymentId +
          "&payment_for=" +
          payment_for +
          "&token=" +
          token
      )
      .then((response) => {
        setPaymentDetails(response.data);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Header />
      <div className="payment-status-container">
        {!isLoading && paymentDetails !== null && (
          <div style={{ margin: "auto" }}>
            {paymentDetails.code == "PAYMENT_ERROR" && (
              <div>
                <h1>Payment Failed</h1>
                <span>Payment has Failed. Please try again</span>
                <br />
                <button
                  id="failed"
                  class="btn btn-signup"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ background: "#dfb11f" }}
                >
                  Go to details
                </button>
              </div>
            )}
            {paymentDetails.code == "PAYMENT_SUCCESS" && (
              <div>
                <h1>Payment is Successful</h1>
                <span>
                  Payment {paymentDetails.merchantTransactionId} is Successful.
                </span>
                <br />
                <button
                  id="pending"
                  class="btn btn-signup"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ background: "#dfb11f" }}
                >
                  Go to details
                </button>
              </div>
            )}
            {paymentDetails.code == "PAYMENT_INITIATED" && (
              <div>
                <h1>Payment was Initiated but not completed yet.</h1>
                <span>
                  Payment {paymentDetails.merchantTransactionId} is Pending.
                </span>
                <br />
                <button
                  id="success"
                  class="btn btn-signup"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ background: "#dfb11f" }}
                >
                  Check Details
                </button>
              </div>
            )}
          </div>
        )}
        {!isLoading && paymentDetails == null && (
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h1>Something went wrong while validating payment status!!!</h1>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PaymentStatus;
