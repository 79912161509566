import React from "react";
import "./header.css";
import mainlogo from "../../assests/images/logo.png";
// import {Link} from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { HashLink as Link } from "react-router-hash-link";
import axios from "axios";
const Header = () => {
  const url = "https://playnoot-api-prod.azurewebsites.net/OnlineLead/CreateLead";
  return (
    <>
      <div className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <Link className="navbar-brand" to={`/`}>
                <img src={mainlogo} alt="LOGO" />
              </Link>
              <button
                className="navbar-toggler"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                  <li className="nav-item ">
                    <Link
                      className="nav-link active fw-bold"
                      aria-current="page"
                      to={`/`}
                    >
                      Home{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link fw-bold"
                      to={`/#aboutus`}
                      smooth
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      About Us{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link fw-bold"
                      smooth
                      tabindex="-1"
                      aria-disabled="true"
                      to={`/#feature`}
                    >
                      Features{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link fw-bold"
                      smooth
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      FAQs{" "}
                    </Link>
                  </li>

                  <li className="nav-item ms-4">
                    <button
                      className="btn btn-signup"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Pre order Sign Up{" "}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mobileview" id="mymenu">
              <div
                className="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  <Link className="navbar-brand" href="javascript:void(0)">
                    <img src={mainlogo} alt="LOGO" />
                  </Link>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <div className="navigation">
                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                      <li className="nav-item ">
                        <Link
                          className="nav-link active fw-bold "
                          aria-current="page"
                          href="#"
                        >
                          <i className="fas fa-home active"></i> Home{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link fw-bold"
                          href="#feature"
                          tabindex="-1"
                          aria-disabled="true"
                        >
                          <i className="fas fa-clipboard-list"></i>About Us{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link fw-bold "
                          href="#aboutus"
                          tabindex="-1"
                          aria-disabled="true"
                        >
                          <i className="fas fa-info-circle"></i> Features{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link fw-bold"
                          href="#advertisement"
                          tabindex="-1"
                          aria-disabled="true"
                        >
                          <i className="fas fa-user-plus"></i>FAQs{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-xlg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                PreOrder Sign Up
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="modal-close-button"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  emailId: "",
                  about: "",
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.emailId) {
                    errors.emailId = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.emailId
                    )
                  ) {
                    errors.emailId = "Invalid email address";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  // setTimeout(() => {
                  // // alert(JSON.stringify(values, null, 2));
                  // setSubmitting();
                  // }, 400);
                  axios
                    .post(
                      "https://playnoot-api-prod.azurewebsites.net/OnlineLead/CreateLead",
                      values
                    )
                    .then((response) => {
                      console.log(response);
                      resetForm();
                      alert("Form Submitted Sucessfully");
                      var b = document.getElementById("modal-close-button");
                      b.click();
                    })
                    .catch((error) => {
                      alert("Error while submitting form");
                      console.log(error);
                    }).finally(() => {
                        setSubmitting(false);
                    })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="labelName">
                          First Name <spna className="text-danger">*</spna>
                        </div>
                        <input
                          className="inputBoxes"
                          type="firstName"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="labelName">
                          Last Name <spna className="text-danger">*</spna>
                        </div>
                        <input
                          className="inputBoxes"
                          type="lastName"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="labelName">
                          Work Email <spna className="text-danger">*</spna>
                        </div>
                        <input
                          className="inputBoxes"
                          type="emailId"
                          name="emailId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emailId}
                        />
                        <div className="text-danger errormsg">
                          {errors.emailId && touched.emailId && errors.emailId}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="labelName">Phone Number</div>
                        <input
                          className="inputBoxes"
                          type="phoneNumber"
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                        />
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="labelName">
                          Tell something about your event
                        </div>
                        <textarea
                          className="inputBoxes"
                          type="text"
                          name="about"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.about}
                        />
                      </div>

                      <div className="d-flex">
                        <Field type="checkbox" name="checked" value="yes" /> I
                        here accept the{" "}
                        <Link to={`/privacypolicy`} data-bs-dismiss="modal">
                          Privacy Policy
                        </Link>{" "}
                        and{" "}
                        <Link
                          to={`/termsandconditions`}
                          data-bs-dismiss="modal"
                        >
                          Terms and Conditions
                        </Link>
                      </div>
                      <div className="modal-footer mt-3 pb-0">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
