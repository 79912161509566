import { useSearchParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Typography } from "@mui/material";

const VerifiedStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  var userName = searchParams.get("username");
  var status = searchParams.get("status");
  return (
    <div style={{ display: "table" }}>
      <div
        style={{
          display: "table-cell",
          height: "calc(100vh)",
          width: "calc(100vw)",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        {status == "true" && (
          <div>
            <CheckCircleIcon
              sx={{ fontSize: 100, color: "green", margin: "auto" }}
            ></CheckCircleIcon>
            <Typography>
              User email <b>{userName}</b> is verfied Successfully.
            </Typography>
          </div>
        )}
        {status == "false" && (
          <div>
            <ErrorIcon
              sx={{ fontSize: 100, color: "red", margin: "auto" }}
            ></ErrorIcon>
            <Typography>Some error occuered while validating email.</Typography>
            <span>
              please reachout at 
              <i>
                <a> developer@playnoot.com</a>
              </i>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifiedStatus;
