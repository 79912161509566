import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App.js";
import Privacy from "../components/Privacypolicy/index.js";
import Terms from "../components/Terms/index.js";
import Error from "../shared/error/index.js";
import Cookies from "../components/Cookies/index.js";
import PaymentStatus from "../components/PaymentStatus/index.js";
import VerifiedStatus from "../components/VerifiedUser/verified-status.component.js";
import CancellationPolicy from "../components/CancellationPolicy/cancellation-policy.component.js";
import ForgetPassword from "../components/ForgetPassword/forget-password.component.js";
import RefundPolicy from "../components/RefundPolicy/refund-policy.component.js";
import DeleteUserPolicy from "../components/DeleteUserPolicy/delete-user-policy.component.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/privacypolicy",
    element: <Privacy />,
  },
  {
    path: "/termsandconditions",
    element: <Terms />,
  },
  {
    path: "/404error",
    element: <Error />,
  },
  {
    path: "/cookies",
    element: <Cookies />,
  },
  {
    path: "/payment-status",
    element: <PaymentStatus />,
  },
  {
    path: "/verification-status",
    element: <VerifiedStatus />,
  },
  {
    path: "/cancellation-policy",
    element: <CancellationPolicy />,
  },
  {
    path: "/reset-password",
    element: <ForgetPassword />,
  },
  {
    path: "/refund-policy",
    element: <RefundPolicy />,
  },
  {
    path: "/delete-account",
    element: <DeleteUserPolicy />,
  },
]);
 
  export default router